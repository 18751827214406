import Icon from 'assets/icons';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useAppDispatch } from 'store';
import { EditBusinessLanguageThunk } from 'store/business/api.thunks';
import { getCognitoUser } from 'store/business/selectors';
import { LangEnum, RoutesConstant, SIDEBAR_NAV } from 'utils/constants';
import i18n from 'utils/i18n/i18n';
import { getBusiness } from 'store/business/selectors';
import { REACT_APP_CDN } from 'utils/enviroment';
import { CSSTransition } from 'react-transition-group';
import { setMenu, setMenuButton } from 'store/generalSlice';
import { openMenuData, showMenuButton } from 'store/generalSlice/selector';
import useWindowDimensions from 'utils/hooks/window';
import { UpdateLang } from 'actions/business/model';
import { Disclosure } from '@headlessui/react';
import { classNames } from 'utils';

interface ILanguageOption {
  name: string;
  title: string;
  id: string;
}
interface INavigation {
  selected?: SIDEBAR_NAV;
}
const Navigation = ({ selected }: INavigation): JSX.Element => {
  const isOpen = useSelector(openMenuData);
  const showButton = useSelector(showMenuButton);
  const { width } = useWindowDimensions();

  const [t] = useTranslation(['Home']);
  const businessUser = useSelector(getCognitoUser);
  const user = useSelector(getBusiness);
  const dispatch = useAppDispatch();
  const nodeRef = useRef(null);
  const { pathname } = useLocation();

  const changeLanguage = async (lang: ILanguageOption) => {
    setSelectedLang(lang);
    if (businessUser) {
      const updateArgs: UpdateLang = {
        id: businessUser.id,
        language: lang.id === 'en' ? LangEnum.EN : LangEnum.ES,
      };
      await dispatch(EditBusinessLanguageThunk(updateArgs));
    }
  };
  const language = [
    { name: `${t('english')} (EN)`, title: 'English (EN)', id: 'en' },
    { name: `${t('spanish')} (ES)`, title: 'Español(ES)', id: 'es' },
  ];

  const [selectedLang, setSelectedLang] = useState<ILanguageOption>(
    language.filter((value) => value.id === i18n.language)[0] ?? {
      name: `${t('english')} (EN)`,
      id: 'en',
    }
  );

  useEffect(() => {
    if (width < 1000) {
      dispatch(setMenu(false));
    }
  }, [width]);

  const openMenu = () => {
    dispatch(setMenu(true));
  };

  const closeMenu = () => {
    dispatch(setMenu(false));
  };

  useEffect(() => {
    i18n.changeLanguage(selectedLang.id);
  }, [selectedLang]);

  useEffect(() => {
    language.map((value) => {
      if (value.id === i18n.language) {
        changeLanguage(value);
      }
    });
  }, [businessUser]);

  const navigationMenu = useMemo(() => {
    return [
      {
        name: t('menu.shifts'),
        href: RoutesConstant.secure.shift,
        current: pathname.includes(RoutesConstant.secure.shift),
      },
      {
        name: t('menu.professionals'),
        href: RoutesConstant.secure.professionals,
        current: pathname.includes(RoutesConstant.secure.professionals),
      },
      {
        name: t('menu.invoice'),
        href: RoutesConstant.secure.invoice,
        current: pathname.includes(RoutesConstant.secure.invoice),
      },
      {
        name: t('menu.hire'),
        href: RoutesConstant.secure.hire,
        current: pathname.includes(RoutesConstant.secure.hire),
      },
    ];
  }, [t, pathname]);

  const navigationProfile = useMemo(() => {
    return [
      {
        name: t('menu.profiles'),
        href: RoutesConstant.secure.profile,
        current: pathname.includes(RoutesConstant.secure.profile),
      },
      {
        name: t('menu.settings'),
        href: RoutesConstant.secure.settings,
        current: pathname.includes(RoutesConstant.secure.settings),
      },
    ];
  }, [t, pathname]);

  return (
    <>
      {showButton && (
        <div className=" w-[60px] p-3 absolute md:relative bg-neutral-200 flex-col justify-between hidden sm:flex min-h-screen">
          <div className="grid gap-11 justify-center">
            <div className="cursor-pointer m-auto" onClick={openMenu}>
              <Icon icon="menu" className="text-primary" size={25} color="black" />
            </div>

            <Link
              to={RoutesConstant.secure.shift}
              className={`cursor-pointer mt-10 p-1 hover:bg-secondary-200 hover:rounded-lg ${
                selected === SIDEBAR_NAV.SHIFTS && 'bg-secondary-200 rounded-lg'
              }`}
            >
              <Icon icon="shifts" size={45} className=" hover-icon" />
            </Link>
            <Link
              to={RoutesConstant.secure.timesheet}
              className={`cursor-pointer p-1 hover:bg-secondary-200 hover:rounded-lg ${
                selected === SIDEBAR_NAV.TIMESHEET && 'bg-secondary-200 rounded-lg'
              }`}
            >
              <Icon icon="timesheet" size={45} className="text-[#9597ae] hover-icon" />
            </Link>
            <Link
              to={RoutesConstant.secure.professionals}
              className={`cursor-pointer p-1 hover:bg-secondary-200 hover:rounded-lg ${
                selected === SIDEBAR_NAV.PROFESSIONALS && 'bg-secondary-200 rounded-lg'
              }`}
            >
              <Icon icon="professionals" size={45} className=" hover-icon" />
            </Link>
            <Link
              to={RoutesConstant.secure.invoice}
              className={`cursor-pointer p-1 hover:bg-secondary-200 hover:rounded-lg text-center ${
                selected === SIDEBAR_NAV.INVOICE && 'bg-secondary-200 rounded-lg'
              }`}
            >
              <Icon icon="invoice" size={45} className="text-[#9597ae] hover-icon" />
            </Link>

            <Link
              to={RoutesConstant.secure.hire}
              className={`cursor-pointer p-1 hover:bg-secondary-200 hover:rounded-lg text-center ${
                selected === SIDEBAR_NAV.HIRE && 'bg-secondary-200 rounded-lg'
              }`}
            >
              <Icon icon="case" size={25} className="text-[#9597ae] hover-icon" />
            </Link>

            <Link
              to={RoutesConstant.secure.profile}
              className={`cursor-pointer p-1 hover:bg-secondary-200 hover:rounded-lg ${
                selected === SIDEBAR_NAV.PROFILES && 'bg-secondary-200 rounded-lg'
              }`}
            >
              <Icon icon="profiles" size={45} className=" hover-icon" />
            </Link>

            <Link
              to={RoutesConstant.secure.support}
              className={`cursor-pointer  p-1 hover:bg-secondary-200 hover:rounded-lg ${
                selected === SIDEBAR_NAV.SUPPORT && 'bg-secondary-200 rounded-lg'
              }`}
            >
              <Icon icon="supportMenu" size={25} className=" my-3 hover-icon ml-2" />
            </Link>
          </div>
          <div className="grid mt-11 justify-center">
            <Link
              to={RoutesConstant.secure.settings}
              className={`cursor-pointer p-1 hover:bg-secondary-200 hover:rounded-lg ${
                selected === SIDEBAR_NAV.SETTINGS && 'bg-secondary-200 rounded-lg'
              }`}
            >
              <Icon icon="config" size={45} className="text-[#9597ae] hover-icon" />
            </Link>
          </div>
        </div>
      )}
      <CSSTransition
        in={isOpen}
        classNames={'fade'}
        timeout={500}
        unmountOnExit
        onEnter={() => dispatch(setMenuButton(false))}
        onExited={() => dispatch(setMenuButton(true))}
      >
        <div ref={nodeRef} className=" lg:flex w-full max-w-[230px] z-50">
          <div className="w-full max-w-[230px] border-black md:relative bg-neutral-200 text-grayText py-4 fixed lg:flex flex-col  px-4 min-h-screen h-md:max-h-none max-h-screen">
            <div className="overflow-y-auto">
              <div className="flex">
                <div className="absolute cursor-pointer -right-4 top-14" onClick={closeMenu}>
                  <div className="bg-white rounded-full w-7 h-7 flex shadow-md">
                    <div className="w-full flex justify-center self-center">
                      <Icon icon="ChevronDown" size={14} className="rotate-90" />
                    </div>
                  </div>
                </div>

                <div className="flex w-full justify-center pt-8 pb-5 mb-6 ">
                  <img
                    className=" border-primary h-10"
                    src={`${REACT_APP_CDN}/img/chambaLogo.svg`}
                    alt={`${REACT_APP_CDN}/img/chambaLogo.svg`}
                  />
                </div>
              </div>
              <div>
                <div className=" w-full flex text-base text-neutralBlue pb-2">
                  <p className="text-neutral-1600">{t(`menu.menu`)}:</p>
                </div>
                <Link
                  to={RoutesConstant.secure.shift}
                  className={`flex flex-row items-center w-full text-center px-6 my-2 hover:bg-secondary-200 hover:rounded-lg ${
                    selected === SIDEBAR_NAV.SHIFTS && 'bg-secondary-200 rounded-lg'
                  }`}
                >
                  <Icon icon="shifts" size={45} className=" hover-icon mr-2" />
                  <p className={`  text-icon text-base text-neutral-1000 `}>{t('menu.shifts')}</p>
                </Link>
                <Link
                  to={RoutesConstant.secure.timesheet}
                  className={`flex flex-row items-center w-full text-center px-6 my-2 hover:bg-secondary-200 hover:rounded-lg ${
                    selected === SIDEBAR_NAV.TIMESHEET && 'bg-secondary-200 rounded-lg'
                  }`}
                >
                  <Icon icon="timesheet" size={45} className=" hover-icon mr-2" />
                  <p className={`  text-icon text-base text-neutral-1000`}>{t('menu.timesheet')}</p>
                </Link>
                <Link
                  to={RoutesConstant.secure.professionals}
                  className={`flex flex-row items-center w-full text-center mb-2 px-6 hover:bg-secondary-200 hover:rounded-lg ${
                    selected === SIDEBAR_NAV.PROFESSIONALS && 'bg-secondary-200 rounded-lg'
                  }`}
                >
                  <Icon icon="professionals" size={45} className=" hover-icon mr-2" />
                  <p className={`  text-icon text-base  text-neutral-1000`}>
                    {t('menu.professionals')}
                  </p>
                </Link>
                <Link
                  to={RoutesConstant.secure.invoice}
                  className={`flex flex-row items-center mb-2 w-full text-center px-6 hover:bg-secondary-200 hover:rounded-lg ${
                    selected === SIDEBAR_NAV.INVOICE && 'bg-secondary-200 rounded-lg'
                  }`}
                >
                  <Icon icon="invoice" size={45} className=" hover-icon mr-2" />
                  <p className={`  text-icon text-base text-neutral-1000 `}>{t('menu.invoice')}</p>
                </Link>

                <Link
                  to={RoutesConstant.secure.hire}
                  className={`flex flex-row items-center py-3 w-full text-center px-6 hover:bg-secondary-200 hover:rounded-lg ${
                    selected === SIDEBAR_NAV.HIRE && 'bg-secondary-200 rounded-lg'
                  }`}
                >
                  <Icon
                    icon="case"
                    size={25}
                    className=" hover-icon mr-2 text-neutral-1000 fill-neutral-1000 ml-2.5"
                  />
                  <p className={`text-icon text-base text-neutral-1000 ml-3`}>{t('menu.hire')}</p>
                </Link>

                <div className="mt-16 w-full flex text-base text-neutralBlue pb-2">
                  <p className="text-neutral-1600">{t(`menu.account`)}:</p>
                </div>

                <Link
                  to={RoutesConstant.secure.profile}
                  className={`flex flex-row items-center mt-2 mb-4 w-full text-center px-6 hover:bg-secondary-200 hover:rounded-lg ${
                    selected === SIDEBAR_NAV.PROFILES && 'bg-secondary-200 rounded-lg'
                  }`}
                >
                  <Icon icon="profiles" size={45} className=" hover-icon mr-2" />
                  <p className={`  text-icon text-base text-neutral-1000 `}>{t('menu.profiles')}</p>
                </Link>

                <Link
                  to={RoutesConstant.secure.support}
                  className={`flex flex-row items-center py-2 mt-2 mb-4 w-full text-center px-6 hover:bg-secondary-200 hover:rounded-lg ${
                    selected === SIDEBAR_NAV.SUPPORT && 'bg-secondary-200 rounded-lg'
                  }`}
                >
                  <Icon icon="supportMenu" size={28} className=" hover-icon mr-4 ml-2" />
                  <p className={`  text-icon text-base text-neutral-1000 `}>{t('menu.support')}</p>
                </Link>
              </div>
            </div>

            <div className="bg-otherGray h-md:absolute bottom-0 left-0 py-4 flex justify-center w-full ">
              <div className=" max-w-[90%]  ">
                <div className="flex flex-row items-center justify-between">
                  <div className="w-[15%] ">
                    <img className="rounded-full" src={user?.logo} alt="" />
                  </div>
                  <div className="flex flex-col pl-1 text-gray max-w-[65%]">
                    <p className="font-bold text-sm truncate">{user?.name}</p>
                    <p className=" text-xs text-[#9597ae] truncate">{user?.email}</p>
                  </div>
                  <Link
                    to={RoutesConstant.secure.settings}
                    className={`flex flex-row text-center px-2 `}
                  >
                    <Icon icon="config" size={45} className="text-[#9597ae] hover-icon" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>

      {/* Reponsive bar */}
      <Disclosure as="nav" className="sm:hidden bg-gray-400 w-full">
        {({ open }) => (
          <div className={`${open ? 'bg-primary' : 'bg-transparent'}`}>
            <div className="mx-auto px-2 sm:px-6 lg:px-8">
              <div
                className={`relative flex items-center ${
                  open ? 'justify-end' : 'justify-between'
                }   h-16`}
              >
                <div className={`flex pr-2 lg:static lg:inset-auto lg:ml-6 lg:pr-0`}>
                  <Disclosure.Button className="inline-flex lg:hidden items-center justify-center p-2 rounded-md">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <Icon size={14.51} className="text-white" icon="close" />
                    ) : (
                      <Icon size={25} className="text-primary" icon="menu" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="lg:hidden flex flex-col bg-primary h-screen  align-middle">
              <div className="px-2 pt-2 pb-3 space-y-1">
                <>
                  <img
                    className=" border-primary max-h-[60px] m-auto mb-10"
                    src={`${REACT_APP_CDN}/text-chamba.svg`}
                    alt={'Chamba Logo'}
                  />

                  <p className="px-3 text-white">Menu:</p>
                  {navigationMenu.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className={classNames(
                        item.current
                          ? 'underline text-white underline-offset-8'
                          : 'text-white hover:bg-gray-700 hover:text-white',
                        'block px-3 py-2 rounded-md font-bold text-3xl'
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}

                  <br />

                  <p className="px-3 text-white">{t('menu.account')}:</p>

                  {navigationProfile.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className={classNames(
                        item.current
                          ? 'underline text-white underline-offset-8'
                          : 'text-white hover:bg-gray-700 hover:text-white',
                        'block px-3 py-2 rounded-md font-bold text-3xl'
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </>
              </div>
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>
    </>
  );
};

export default Navigation;
