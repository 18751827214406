import { useElements, useStripe } from '@stripe/react-stripe-js';
import { useMemo, useState } from 'react';
import CardFormAction from './CardForm.Action';
import { useNavigate, useParams } from 'react-router-dom';

const CardFormPresenter = () => {
  const action = CardFormAction();
  const [readyCard, setReadyCard] = useState({ number: false, expiry: false, ccv: false });
  const elements = useElements();
  const stripe = useStripe();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [succeeded, setSucceeded] = useState(false);
  const navigate = useNavigate();

  const isReadyForPayment = useMemo(() => {
    return readyCard.ccv && readyCard.expiry && readyCard.number;
  }, [readyCard]);

  const onSubmit = async () => {
    try {
      if (elements && stripe && id) {
        setIsLoading(true);
        const elementNumber = elements.getElement('cardNumber');
        if (elementNumber) {
          const result = await stripe.createPaymentMethod({
            type: 'card',
            card: elementNumber,
          });

          if (result.paymentMethod?.id)
            await action.executePayInvoice(id, result.paymentMethod?.id);
          setSucceeded(true);
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onGoBack = () => {
    navigate(-1);
  };

  return {
    readyCard,
    isLoading,
    id,
    setReadyCard,
    isReadyForPayment,
    onSubmit,
    succeeded,
    onGoBack,
  };
};

export default CardFormPresenter;
